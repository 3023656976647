import React from 'react';
import { graphql } from 'gatsby';

import { StaticImage } from 'gatsby-plugin-image';
import { ListTLink, CTACard, animateObjects, newContent, ListTLinkWithDefaults } from "../../components/Layout";
import SEO from "../../components/seo";
import GiveContentTrans from "../../components/GiveContentTrans";
import MagneticButton from '../../components/MagneticButton';

import Icon from '../../components/Icon';

import Layout from '../../components/Layout';



/* // Create component in same file. We use it just here so keep it in same file. Otherwise export etc,.
function WorkPreview({ work }) {
  return (
    <div>
      <Link to={`/work/${work.slug}`}>
        <h2>
          <span className="mark">{work.title}</span>
        </h2>
      </Link>
     
      <Img fluid={work.image.asset.fluid} alt={work.title} />
    </div>
  );
} */

// markup
// const IndexPage = ({ data }) => {

  function AboutPage() {



  return ( 
    <Layout>
    <main className="open-service-page outer-grid"> <GiveContentTrans language="et"> 
    
  
        
        <SEO
  title="Kodulehe tegemine ja disain – Läbimõeldud kodulehed"
  description="Koduleht on üks odavaim reklaamikanal. Hea disainiga koduleht ei ole kulu, vaid investeering, mis loob usaldust ja toob kliente."
  image="/pagecover-teenused.jpg"
  structuredData={{
    "@context": "https://schema.org",
    "@graph": [
      {
        "@type": "Service",
        "name": "Kodulehe tegemine",
        "provider": {
          "@type": "Organization",
          "name": "Give Digiagentuur",
          "url": "https://give.ee"
        },
        "areaServed": {
          "@type": "Country",
          "name": "Estonia"
        },
        "serviceType": "Kodulehe disain, arendus ja optimeerimine",
        "description": "Pakume unikaalse disainiga kodulehe erilahendusi ettevõtetele, startuppidele ja asutustele.",
        "offers": {
          "@type": "Offer",
          "price": "Alates 2000€",
          "priceCurrency": "EUR",
          "url": "https://give.ee/teenused/kodulehed/"
        }
      },
      {
        "@type": "LocalBusiness",
        "name": "Give Digiagentuur",
        "url": "https://give.ee",
        "logo": "https://give.ee/give-digital-agency-logo-white-bg.png",
 
        "address": {
          "@type": "PostalAddress",
          "addressLocality": "Tallinn",
          "addressCountry": "EE"
        },
        "telephone": "+3725533913",
        "sameAs": [
          "https://www.linkedin.com/company/give-digital-agency/"
        ]
      },
      {
        "@type": "FAQPage",
        "mainEntity": [
          {
            "@type": "Question",
            "name": "Miks peaksin valima Give digiagentuuri kodulehe tegemiseks?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Kui soovite digiagentuuri, kes hoolib oma tööst ja suudab Eestis pakkuda maailmatasemel kodulehe disaini, siis oleme õige valik. Oleme töötanud või teinud projektipõhist koostööd juhtivate Eesti digi- ja reklaamiagentuuridega ning omame algväärtust, millest paremat teenust soovime pakkuda. Valime veebiprojektid, mida tahame kujundada ja arendada ning kus näeme, et saame kodulehele lisada väärtust. Pärast kodulehe valmimist võite olla kindlad, et teile jääb partner, kes ei kao ära ja on alati kättesaadav."
            }
          },
          {
            "@type": "Question",
            "name": "Mis on kodulehe tegemise hind?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Veebilehtede projektid on väga erineva mahu ja keerukusega ning ühte kodulehe tegemise hinda ei saa välja kirjutada. On veebiagentuure, kes teevad teile uue kodulehe mõnesaja euro eest. Me ei kasuta üldiselt valmis veebikujundusi ja kõik kodulehe projektid kujundame erilahendusena vastavalt teie vajadustele. Keskmise kodulehe tegemisel võiks hakkama saada neljakohalise numbriga. Kõige soodsama hinnaga kodulehte saame pakkuda kasutades Frameri platvormi."
            }
          },
          {
            "@type": "Question",
            "name": "Kas te pakute ka veebilehe haldust ja hooldust?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Jah, pakume kodulehe haldust! Aitame teid nii kodulehekülje testimise, analüütika kui sisu uuendusega. Vajadusel teeme koostööd nt. SEO spetsialistide, fotograafide ja sisuloojatega. Frameri ja Webflow no-code tarkvara põhjale ehitatud veebilehtede suur pluss on see, et nad ei vaja eraldi kodulehe tarkvara uuendamist. Wordpressiga tehtud kodulehti soovitame uuendada 1-2 korda aastas."
            }
          },
          {
            "@type": "Question",
            "name": "Kuidas suurendada müüki läbi kodulehe?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Veenduge, et teie kodulehekülg avaneb kiiresti, on kaasaegne ja usaldusväärse disainiga ning selge sisuga. Kasutage sobivaid märksõnu ja SEO-strateegiaid, et teie koduleht oleks otsingumootorites leitav. Tehke ostuprotsess võimalikult lihtsaks ja selgeks, vähendades takistusi kodulehelt ostude sooritamisel. Pakkuge kiiret ja professionaalset klienditeenindust, et vastata küsimustele ja aidata võimalike ostude tegemisel."
            }
          },
          {
            "@type": "Question",
            "name": "Kas saame ligipääsu kodulehe failidele?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Me ei hoia kodulehe koodi lukus ega piira teie ligipääsu. Meie ärimudel ei põhine kodulehe tellijate enda juures kinnihoidmisel. Kodulehed kujundame enamasti Figmas. Kui teil on oma meeskond või partner, kes tegeleb veebiarendusega, siis jagame kõiki vajalikke kodulehe disaini tööfaile. Kui koduleht on tehtud Frameri või Webflow tarkvara peale, on teil täielik ligipääs. Wordpressi või muu ilma no-code tarkvarata arendatud kodulehekülje koodiga on keerulisem, kuna serveris olev kood võib olla varasemalt kompileeritud."
            }
          },
          {
            "@type": "Question",
            "name": "Kuidas teha ise koduleht?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Koduleht on teie ettevõtte üks kõige olulisemaid reklaamikanaleid. Jäta see töö veebispetsialistidele ja teenid oma investeeringu kindlasti tagasi. Kui siiski soovid ise kodulehte teha, siis soovitame alustada Webflow või Framer platvormidega ning tutvuda nende õpetustega ja Youtube'i videotega."
            }
          },
          {
            "@type": "Question",
            "name": "Kui kaua võtab kodulehe tegemine aega?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Keskmiselt võtab kodulehe tegemine aega 4 kuni 8 nädalat. Palju sõltub ka sellest, kui kiiresti olete valmis kodulehe kujundusele tagasisidet andma ja milliseid materjale tuleb veebilehe sisu jaoks luua."
            }
          },
          {
            "@type": "Question",
            "name": "Kuidas tagate, et tehtud koduleht vastab minu brändi isikupärale?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Meie UI/UX disainerid konsulteerivad teiega, et mõista teie brändi visuaalset identiteeti ja integreerida need loodavasse kodulehe kujundusse. Me ei kasuta valmis malle – iga koduleht on unikaalne ja loodud just teie brändi ning ärieesmärkide järgi."
            }
          },
          {
            "@type": "Question",
            "name": "Veebilehe tegemine, kust alustada?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Kõigepealt mõtle läbi, kellele on koduleht suunatud ja millised on selle eesmärgid. Kui teed veebilehte oma ärile, soovitame kasutada ekspertide abi. Kirjuta meile või otsi Google’ist digiagentuure. Kindlasti küsi, kas pakutakse valmis templiitidel põhinevaid lahendusi või erilahendusi. Kodulehe projekti kiiremaks kulgemiseks soovitame juba enne uurida, millised veebilehed sulle meeldivad."
            }
          },
          {
            "@type": "Question",
            "name": "Miks te minu kodulehe tegemise päringule ei ole vastanud?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Me vastame igale kodulehe tegemise päringule enamasti 24 tunni jooksul. Kui sa ei ole vastust saanud, kontrolli kindlasti oma spämmi kausta. Kui ka sealt ei leia, saada palun uus päring või helista meile. Kui saatsid meile külma müügipakkumise, jätame endale õiguse mitte vastata."
            }
          }
        ]
      }
      
    ]
  }}
/>
        
        
        
        </GiveContentTrans> <GiveContentTrans language="en"> <SEO
  title="Website design and development – Crafted websites"
  description="A website is one of the most cost-effective marketing channels. A well-designed website is not an expense, but an investment that builds trust and attracts customers."
  image="/pagecover-en-default.jpg"
  structuredData={{
    "@context": "https://schema.org",
    "@graph": [
      {
        "@type": "Service",
        "name": "Website Design and Development",
        "provider": {
          "@type": "Organization",
          "name": "Give Digital Agency",
          "url": "https://give.ee"
        },
        "areaServed": {
          "@type": "Country",
          "name": "Estonia"
        },
        "serviceType": "Website design, development, and optimization",
        "description": "We offer custom website solutions tailored for businesses, startups, and institutions.",
        "offers": {
          "@type": "Offer",
          "price": "Starting from €2000",
          "priceCurrency": "EUR",
          "url": "https://give.ee/en/services/websites/"
        }
      },
      {
        "@type": "LocalBusiness",
        "name": "Give Digital Agency",
        "url": "https://give.ee",
        "logo": "https://give.ee/give-digital-agency-logo-white-bg.png",
        "address": {
          "@type": "PostalAddress",
          "addressLocality": "Tallinn",
          "addressCountry": "EE"
        },
        "telephone": "+3725533913",
        "sameAs": [
          "https://www.linkedin.com/company/give-digital-agency/"
        ]
      }
    ]
  }}
/>
</GiveContentTrans>

      <section className="hero">
        <GiveContentTrans language="et">
          <h1>Kodulehe tegemine.</h1>
        </GiveContentTrans>
        <GiveContentTrans language="en">
          <h1>Website creation. User friendly&nbsp;design</h1>
        </GiveContentTrans>

        <div className="page-copy">
          <GiveContentTrans language="et">
            <p>
            Kodulehe disain, mis eristub. Kas tahad kodulehte, mis on meeldejääv, kasutajasõbralik ja usaldusväärne? Kui oled siin, siis ilmselt soovid, et su koduleht teeniks raha. Hea disainiga koduleht võib suurendada müüki kuni 3x.
</p>


            <a href="https://cal.com/tanels/give-free-calls" className="call-cta">
    <MagneticButton
      addionalClasses="cta cta-xl"
      buttonLabel="Broneeri tasuta videokõne"
      magneticDistance="150"
      magneticSpeed="0.4"
    />
  </a>

          </GiveContentTrans>
          <GiveContentTrans language="en">
          <p>
    Standout website design that makes an impact. Do you want a custom website that is memorable, user-friendly, and trustworthy? If you're here, you likely want your website to generate revenue. A well-designed website can increase conversions by up to 3x.
</p>
          </GiveContentTrans>
        </div>
      </section>

      <div className="img-100">

        <GiveContentTrans language="et">
          <StaticImage
            src="../../assets/kodukad.jpg"
            alt="Kaasaegne ja kasutajasõbralik kodulehe disain – meie disainitud unikaalsed veebilehed"
            title="Kodulehe disain ja tegemine – professionaalsed veebilahendused"
            layout="fullWidth"
          />
        </GiveContentTrans>
        <GiveContentTrans language="en">
          <StaticImage
            src="../../assets/kodukad.jpg"
            alt="Modern and user friendly homepage design mockups done by us"
            title="Website design and creation"
            layout="fullWidth"
          />
        </GiveContentTrans>
      </div>

      <section className="description-section inner-grid">
        <div className="content-col page-copy">
          <GiveContentTrans language="et">
            <h2>Koduleht, mis loob usaldust ja suurendab müüki</h2>

            <p>
    Koduleht on sinu ettevõtte kõige olulisem digikanal ja müügiinstrument. Kui tahad kodulehte, mis teenib raha, peab see olema kasutajale suunatud, visuaalselt läbimõeldud disaini ning kasutusmugavusega ja 
    usaldusväärne. Hästi disainitud veebileht tugevdab brändi, parandab kasutajakogemust ja toob rohkem kliente.  
</p>
<p>
    Give digiagentuuris kujundame kodulehtede erilahendusi, mille eesmärk on ärilisi tulemusi parandada. Valime projekte, kus näeme potentsiaali ning kus saame päriselt mõjutada ettevõtte kasvu.
</p>


   
          </GiveContentTrans>
          <GiveContentTrans language="en">
            <h2>Websites that are optimized for conversions</h2>
            <p>
  Your website is one of your company's most important marketing channels. Want a website that generates revenue? You need a user-focused site with quality branded design and user-friendly features. Such a website builds trust and boosts sales.
</p>
<p>
  We create custom website solutions and select projects we enjoy working on, where we see potential to improve business results.
</p>

          </GiveContentTrans>
        </div>
      </section>


      <GiveContentTrans language="et">
        <section className="description-section inner-grid">

          <div className="section-title">
          <h2>Meie loodud kodulehe disainid ja erilahendused</h2>
          </div>

        <div className="content-row has-media">

        <div className="web-grid">

            <div className="web-grid-item">
  
            <StaticImage
                  src="../../assets/kodulehe-kujundused/toote-tutvustus-kodulehe-kujundus.png"
                  alt="Fintech kodulehe kujundus. Interaktiivne veebilahendus"
                  layout="fullWidth"
                  title="Fintech kodulehe kujundus ja arendus"
                />
            </div>

            <div className="web-grid-item">
  
  <StaticImage
        src="../../assets/kodulehe-kujundused/portaali-kodulehe-kujundus.png"
        alt="Veebiportaali kujundus"
        layout="fullWidth"
        title="Kodulehe kujundus"
      />
  </div>

  <div className="web-grid-item">
  
  <StaticImage
        src="../../assets/kodulehe-kujundused/fintech-kodulehe-kujundus2.png"
        alt="Fintech kodulehe kujundus kontseptsioon"
        layout="fullWidth"
        title="Puhas ja selge kodulehe kujundus"
      />
  </div>

  <div className="web-grid-item">
  
  <StaticImage
        src="../../assets/kodulehe-kujundused/fintech-kodulehe-kujundus.png"
        alt="Fintech kodulehe kujundus aafrikasse"
        layout="fullWidth"
        title="Kodulehe kujundus"
      />
  </div>

  <div className="web-grid-item">
  
  <StaticImage
        src="../../assets/kodulehe-kujundused/auto-tuuningu-kodulehe-kujundus.png"
        alt="Auto tuuningu kodulehe kujundus"
        layout="fullWidth"
        title="Kodulehe kujundus auto tuuningu ettevõttele USAs"
      />
  </div>

  <div className="web-grid-item">
  
  <StaticImage
        src="../../assets/kodulehe-kujundused/restorani-kodulehe-kujundus.png"
        alt="Restorani kodulehe kujundus"
        layout="fullWidth"
        title="Kodulehe kujundus"
      />
  </div>

  <div className="web-grid-item">
  
  <StaticImage
        src="../../assets/kodulehe-kujundused/meditsiini-kodulehe-kujundus.png"
        alt="Ravimite edasimüüja kodulehe kujundus"
        layout="fullWidth"
        title="Kodulehe kujundus"
      />
  </div>

  <div className="web-grid-item">
  
  <StaticImage
        src="../../assets/kodulehe-kujundused/meditsiini-kodulehe-kujundus2.png"
        alt="Meditsiini teemalise kodulehe kujundus"
        layout="fullWidth"
        title="Kodulehe kujundus"
      />
  </div>

  <div className="web-grid-item">
  
  <StaticImage
        src="../../assets/kodulehe-kujundused/kampaaniaveebi-kujundus.png"
        alt="Kampaania kodulehe kujundus"
        layout="fullWidth"
        title="Kodulehe kujundus"
      />
  </div>


  <div className="web-grid-item">
  
  <StaticImage
        src="../../assets/kodulehe-kujundused/teatri-kodulehe-kujundus.png"
        alt="Teatri kodulehe kujundus"
        layout="fullWidth"
        title="Kodulehe kujundus"
      />
  </div>


        


  

          </div>
       </div>
       </section>
       </GiveContentTrans>


       <GiveContentTrans language="en">
        <section className="description-section inner-grid">

          <div className="section-title">
          <h2>Websites designed by us</h2>
          </div>

        <div className="content-row has-media">

        <div className="web-grid">

        <div className="web-grid-item">
  
  <StaticImage
        src="../../assets/kodulehe-kujundused/toote-tutvustus-kodulehe-kujundus.png"
        alt="Fintech website design"
        layout="fullWidth"
        title="Webdesign"
      />
  </div>

  <div className="web-grid-item">

<StaticImage
src="../../assets/kodulehe-kujundused/portaali-kodulehe-kujundus.png"
alt="Web portal design"
layout="fullWidth"
title="Webdesign"
/>
</div>

<div className="web-grid-item">

<StaticImage
src="../../assets/kodulehe-kujundused/fintech-kodulehe-kujundus2.png"
alt="Fintech website design"
layout="fullWidth"
title="Webdesign"
/>
</div>

<div className="web-grid-item">

<StaticImage
src="../../assets/kodulehe-kujundused/fintech-kodulehe-kujundus.png"
alt="Fintech website design"
layout="fullWidth"
title="Webdesign"
/>
</div>

<div className="web-grid-item">

<StaticImage
src="../../assets/kodulehe-kujundused/auto-tuuningu-kodulehe-kujundus.png"
alt="Car tuning website design"
layout="fullWidth"
title="Webdesign"
/>
</div>

<div className="web-grid-item">

<StaticImage
src="../../assets/kodulehe-kujundused/restorani-kodulehe-kujundus.png"
alt="Restaurant website design"
layout="fullWidth"
title="Webdesign"
/>
</div>

<div className="web-grid-item">

<StaticImage
src="../../assets/kodulehe-kujundused/meditsiini-kodulehe-kujundus.png"
alt="Medical website design"
layout="fullWidth"
title="Webdesign"
/>
</div>

<div className="web-grid-item">

<StaticImage
src="../../assets/kodulehe-kujundused/meditsiini-kodulehe-kujundus2.png"
alt="Medical website design"
layout="fullWidth"
title="Webdesign"
/>
</div>

<div className="web-grid-item">

<StaticImage
src="../../assets/kodulehe-kujundused/kampaaniaveebi-kujundus.png"
alt="Campaign website design"
layout="fullWidth"
title="Webdesign"
/>
</div>


<div className="web-grid-item">

<StaticImage
src="../../assets/kodulehe-kujundused/teatri-kodulehe-kujundus.png"
alt="Theater website design"
layout="fullWidth"
title="Webdesign"
/>
</div>
          </div>
       </div>
       </section>
       </GiveContentTrans>



       <section className="description-section inner-grid">
        <div className="content-col page-copy">
          <GiveContentTrans language="et">
          <h2>Kodulehe tegemine – kuidas valmib tulemuslik veebileht?</h2>


          <p>
    Tugev koduleht ei teki juhuslikult. Kõik algab läbimõeldud protsessist – meil on üle 20 aastat kogemust eri tüüpi kodulehtede tegemisega, alates brändiveebidest kuni keerukate UI/UX erilahendusteni.
</p>

<p>
    Kodulehe tegemine ei ole lihtsalt tehniline arendus – see on strateegiline töö, mis ühendab veebilehe disaini, kasutajakogemuse (UX) ja sisuloome. Ükski agentuur ei loo ideaalset kodulehte ühe korraga, kuid meie töömudel tagab, et veebileht oleks kiirem, intuitiivsem ja edestaks teie konkurente.
</p>

<p>
    Iga projekt algab analüüsist ja planeerimisest. Kaardistame teie brändi, sihtgrupi ja ärieesmärgid ning töötame välja lahenduse, mis loob usaldust ja toob tulemusi. Koduleht ei pea lihtsalt olemas olema – see peab töötama ja kasvatama müüki.
</p>

  

   
          </GiveContentTrans>
          <GiveContentTrans language="en">
            <h2>Websites creation process</h2>
            <p>
  How do we create a website that delivers above-average results? The foundation is nearly 20 years of experience in making various types of websites.
</p>

<p>
  Keep in mind that creating a website is a process, and no agency can build the perfect website in one go. We have a well-established yet flexible working model to stay ahead of the competition.
</p>

<p>
  We start website development with planning. We map out your company's needs and wants for the site, listen to your ideas and opportunities. This allows us to analyze which content and design will best achieve your goals. For every web project, we consider your brand and target audience.
</p>

          </GiveContentTrans>
        </div>
      </section>


      <section className="description-section inner-grid">
        <GiveContentTrans language="et">
          <h2>Kodulehe disain – veebilehe esmamulje loeb</h2>
        </GiveContentTrans>
        <GiveContentTrans language="en">
          <h2>Web design</h2>
        </GiveContentTrans>
        <div className="content-row has-media">
          <div className="content-col-half page-copy">
            <GiveContentTrans language="et">
            <p>
    Kasutajal kulub teie kodulehest esmamulje kujundamiseks vaid 0,05 sekundit. Selle aja jooksul ei müü tekst ega sisu – ainus, mis loeb, on veebilehe visuaalne disain ja kasutajakogemus.
</p>

<p>
    Hea kodulehe disain ei ole lihtsalt ilu pärast – see peab looma usaldust, peegeldama brändi väärtusi ja suunama külastajad tegutsema. Meie loodud veebilehed on moodsad, selged ja konversioonidele optimeeritud.
</p>

<h3>Kogenud kodulehe disainer</h3>

<p>
    Give tugevus on aastatepikkune praktiline kogemus eri valdkondade kodulehtede disainimisel. Me ei kasuta valmis malle – iga koduleht on unikaalne ja loodud just teie brändi ning ärieesmärkide järgi.
</p>
            </GiveContentTrans>
            <GiveContentTrans language="en">
            <p>
  On average, it takes a user 0.05 seconds to form an opinion about your website. Even the best copywriter or sales specialist can't make an impact in that time.
</p>

<p>
  Forming an opinion about your brand is primarily the job of your website's design. Our websites feature modern and user-friendly designs.
</p>

<h3>Website Designer</h3>
<p>
  Our strength lies in our experienced web designer. Practical experience with various types and industries of websites allows us to design solutions that deliver results quickly. You can be sure you'll get a unique website designed with your brand in mind.
</p>
            </GiveContentTrans>
          </div>
          <div className="media content-col-half">
            <GiveContentTrans language="et">
              <StaticImage
                src="../../assets/minimalistlik-veebidisain.jpg"
                alt="Minimalistlik moodne kodulehe kujundus"
                layout="fullWidth"
              />
            </GiveContentTrans>
            <GiveContentTrans language="en">
              <StaticImage
                src="../../assets/minimalistlik-veebidisain.jpg"
                alt="Minimal modern webdesign"
                layout="fullWidth"
              />
            </GiveContentTrans>
          </div>
        </div>
        <div className="content-row">
          <div className="content-col-half page-copy">
            <GiveContentTrans language="et">
            <h3>Kodulehe struktuur ja wireframe'id</h3>
            <p>
    Koduleht võib olla nii ühe lehega maandumisleht kui ka mahukas veebiportaal. Struktuuri selgitamiseks koostame sitemapi, mis määrab kodulehe sisujaotuse ja navigeerimise teekonna eri alamlehtede vahel.
</p>

<p>
Wireframe’id on kodulehe esialgsed skeemid, mis kujutavad lehe ülesehitust, põhielemente ja nende paigutust. Need aitavad mõista, kuidas veebileht hakkab funktsioneerima ja milline saab olema kasutajakogemus (UX).  
    Kodulehe loomisel saame ühiselt arutada, millisel kujul wireframe’id ja kujunduskavandid on kõige mõistlikumad.
</p>
            </GiveContentTrans>
            <GiveContentTrans language="en">
            <h3>Website structure and wireframes</h3>

            <p>
  A website can be a single-page landing site or a web portal with hundreds of content pages. The sitemap outlines the distribution of content on the website and the navigation path between various subpages.
</p>
<p>
  Wireframes are simple and concise design layouts of website views, illustrating the structure, elements, and their placement. Wireframes help understand the functionality of the website. During the website development process, we can discuss the suitable formats for wireframes and design layouts for your project.
</p>

            </GiveContentTrans>
          </div>
          <div className="content-col-half page-copy">
            <GiveContentTrans language="et">
              <h3>Kohanduv ehk responsive kodulehe disain</h3>
              <p>
              Responsive kodulehe disain tagab, et teie veebileht kohandub automaatselt erinevate ekraanisuurustega ja pakub head kasutajakogemust igas seadmes. Hästi läbimõeldud ja ligipääsetav kodulehe disain muudab info kättesaadavuse lihtsaks ja parandab kasutuskogemust.
</p>

<p>
    Veebidisainer loob kodulehe visuaali (Figmas, Sketchis vms disainitarkvaras), valmistades eraldi kujundusvaated nii lauaarvutile kui mobiilile.  
    Paljud agentuurid jätavad selle sammu vahele, kuid detailne disain erinevatele ekraanidele aitab tagada kvaliteetse ja sujuva lõpptulemuse.
</p>

            </GiveContentTrans>
            <GiveContentTrans language="en">
            <h3>Responsive design</h3>
            <p>
  Responsive design ensures that your website looks good on various devices. A user-friendly and accessible website design ensures the findability of information.
</p>
<p>
  The web designer creates (using web design software like Figma, Sketch, etc.) the homepage and subpage design views for different devices. Typically, we design desktop and mobile views for all subpages of the website.
  Many web agencies neglect this task, but it significantly affects the quality of the end result.
</p>
            </GiveContentTrans>
          </div>
        </div>
        <div className="content-row">
          <div className="content-col-half page-copy">
            <GiveContentTrans language="et">
              <h3>Kodulehe konversiooni optimeerimine</h3>
              <p>
                Omame kogemust müügile orienteeritud kodulehtede
                kujundamisel, aga erinevalt spetsiaalselt CRO
                agentuuridest, pöörame rohkem rõhku kodulehe disainile.
                Kvaliteetne veebidisain loob pikaajalist väärtus.
              </p>
            </GiveContentTrans>
            <GiveContentTrans language="en">
              <h3>Website Conversion Optimization</h3>
              <p>
  We have experience in designing sales-oriented websites, but unlike dedicated CRO agencies, we focus more on web design. Quality web design creates long-term value.
</p>
            </GiveContentTrans>
          </div>
          <div className="content-col-half page-copy">
            <GiveContentTrans language="et">
              <h3>Kodulehe prototüübi kujundamine</h3>
              <p>
                Kodulehe prototüüp aitab
                mõista kodulehe lõpplahendust ja seda kasutajate peal testida. Nii saab
                UI/UX disainer kujundust täiendada.
              </p>
              <p>
        Animeeritud prototüüpe kasutame kodulehe visualiseerimiseks ja lähteülesandeks programmeerijatele.
              </p>
            </GiveContentTrans>
            <GiveContentTrans language="en">
              <h3>Website prototyping</h3>
              <p>
                Clickable website prototypes are used to test ideas and designs.
                A visual overview helps clients understand the final solution.
                Website prototyping saves time and allows solutions to be improved based
                on user feedback. We use animated prototypes to visualize ideas
                and as a starting point for the development team.
              </p>
            </GiveContentTrans>
          </div>
        </div>
        <div className="content-row">
          <GiveContentTrans language="et">
            <CTACard
              to="/teenused/maandumislehed/"
              exit={{
                length: 1,
                zIndex: 2, // exit default is 0
                trigger: ({ exit, e, node }) => animateObjects(exit, node),
              }}
              entry={{
                delay: 0.5,
                length: 0.6,
                trigger: ({ entry, node }) => newContent(node),
              }}
            >
              Maandumislehtede tegemine
            </CTACard>

            <CTACard
              to="/teenused/ui-ux-disain/"
              exit={{
                length: 1,
                zIndex: 2, // exit default is 0
                trigger: ({ exit, e, node }) => animateObjects(exit, node),
              }}
              entry={{
                delay: 0.5,
                length: 0.6,
                trigger: ({ entry, node }) => newContent(node),
              }}
            >
              UI/UX Disain
            </CTACard>
          </GiveContentTrans>
          <GiveContentTrans language="en">
            <CTACard
              to="/services/landingpages/"
              exit={{
                length: 1,
                zIndex: 2, // exit default is 0
                trigger: ({ exit, e, node }) => animateObjects(exit, node),
              }}
              entry={{
                delay: 0.5,
                length: 0.6,
                trigger: ({ entry, node }) => newContent(node),
              }}
            >
              Landing page creation
            </CTACard>

            <CTACard
              to="/services/ui-ux-design/"
              exit={{
                length: 1,
                zIndex: 2, // exit default is 0
                trigger: ({ exit, e, node }) => animateObjects(exit, node),
              }}
              entry={{
                delay: 0.5,
                length: 0.6,
                trigger: ({ entry, node }) => newContent(node),
              }}
            >
              UI/UX Design
            </CTACard>
          </GiveContentTrans>
        </div>
      </section>

      <section className="description-section inner-grid">
        <GiveContentTrans language="et">
          <h2>Kodulehe programmeerimine</h2>
        </GiveContentTrans>
        <GiveContentTrans language="en">
          <h2>Web development</h2>
        </GiveContentTrans>
        <div className="content-row has-media">
          <div className="content-col-half page-copy">
            <GiveContentTrans language="et">
              <p>
                Kodulehe kujundusest teeme brauseritele sobiva front-end koodi (html/css/js). Enamike kodulehe projektide puhul kirjutame koodi ise ja töö kiirendamiseks kasutame 
                erinevaid raamistikke näiteks Tailwind ja Bootstrap. 
                </p>
                <p>
                Kasutame <ListTLinkWithDefaults namedTo="blogpost-et::mis-on-no-code">no-code veebilahendusi</ListTLinkWithDefaults> nagu <ListTLinkWithDefaults namedTo="blogpost-et::miks-valida-webflow">Webflow</ListTLinkWithDefaults> ja <ListTLinkWithDefaults namedTo="blogpost-et::framer-kodulehekuljed">Framer</ListTLinkWithDefaults>. 
                Sisuhaldussüsteemidest (CMS) kasutame Wordpressi. Seda kas on mõistlik kasutada keerukamat CMS süsteemi või mitte saame projekti planeerimise käigus otsustada.
                </p>

            </GiveContentTrans>
            <GiveContentTrans language="en">
              <p>
                We use Wordpress and Webflow as content management systems
                (CMS), but we understand that not all projects require a CMS.
                Integrating a CMS can add complexity to a project, and websites
                built on Wordpress require periodic updates. We can discuss
                together which solution would be the most practical for your
                specific needs.
              </p>
            </GiveContentTrans>
          </div>
          <div className="media content-col-half">
            <GiveContentTrans language="et">
              <StaticImage
                src="../../assets/Webflow-kodulehekülje-tooriist.jpg"
                alt="Kodulehe tegemine Webflows"
                layout="fullWidth"
              />
            </GiveContentTrans>
            <GiveContentTrans language="en">
              <StaticImage
                src="../../assets/Webflow-kodulehekülje-tooriist.jpg"
                alt="Website made in Webflow"
                layout="fullWidth"
              />
            </GiveContentTrans>
          </div>
        </div>

        <div className="content-row has-media">
          <div className="media content-col-half">
            <GiveContentTrans language="et">
              <StaticImage
                src="../../assets/Kodulehekülje-programmeerimine.jpg"
                alt="Veebilehekülje programmeerimine"
                layout="fullWidth"
              />
            </GiveContentTrans>
            <GiveContentTrans language="en">
              <StaticImage
                src="../../assets/Kodulehekülje-programmeerimine.jpg"
                alt="Website development"
                layout="fullWidth"
              />
            </GiveContentTrans>
          </div>
          <div className="content-col-half page-copy">
            <GiveContentTrans language="et">
              <p>
                Programmeerime kodulehe erilahendusi. Liidestame erinevaid teenuseid ning
                vajadusel loome API-sid. Kodulehtede tegemise tehniline kompetents on PHP-st
                Reactini. Mida teie kodulehe tegemisel kasutada, seda otsustame koos. Mõtleme kliendipõhiselt ja valime lahendused, mis
                täidavad kodulehele tegemisel püstitatud eesmärke.
              </p>
            </GiveContentTrans>
            <GiveContentTrans language="en">
              <p>
                We also develop custom solutions, and we can integrate different
                services and create APIs as needed. Our technical expertise
                spans from PHP to React, and we always choose solutions that
                fulfill your specific goals.
              </p>
            </GiveContentTrans>
          </div>
        </div>

        </section>






        <GiveContentTrans language="et">
        <section className="description-section inner-grid">

          <div className="section-title">
          <h3>Korduma Kippuvad Küsimused</h3><h2>Kodulehe tegemise kohta</h2>
          </div>

        <div className="content-row has-media">
        <div id="faq">
  
  <ul>
    <li>
      <input type="checkbox" defaultChecked></input>
      <div className="faq-arrow"><Icon name="arrowleft" /></div>

      <h3>Miks peaksin valima Give digiagentuuri kodulehe tegemiseks?</h3>
      <div className="answer">
      <p>Kui soovite digiagentuuri kes hoolib oma tööst ja suudab Eestis pakkuda maailmatasemel kodulehe disaini siis oleme õige valik.</p>
      <p>Oleme töötanud või teinud projektipõhist koostööd juhtivate Eesti digi- ja reklaamiagentuuridega ning omame algväärtust millest paremat teenust soovime pakkuda.</p>
      <p>Valime veebiprojektid mida tahame kujundada ja arendada ning kus näeme, et saame kodulehele lisada väärtust. 
        Pärast kodulehe valmimist võite olla kindlad, et teile jääb partner kes ei kao ära ja on alati kättesaadav.
      </p>
      </div>
    </li>
    <li>
      <input type="checkbox" defaultChecked></input>
      <div className="faq-arrow"><Icon name="arrowleft" /></div>
      <h3>Mis on kodulehe tegemise hind?</h3>
      <div className="answer">
      <p>Veebilehtede projektid on väga erineva mahu ja keerukusega ning ühte kodulehe tegemise hinda ei saa välja kirjutada. On veebiagentuure kes teevad teile uue kodulehe mõnesaja euro eest.</p>
      <p> Me ei kasuta üldiselt valmis veebikujundusi ja kõik kodulehe projektid kujundame erilahendusena vastavalt teie
        vajadustele. Keskmise kodulehe tegemisel võiks hakkama saada nelja kohalise numbriga. Kõige soodsama hinnaga kodulehte saame pakkuda kasutades Frameri platvormi. 
        </p>
        <p>Pakume ka fikseeritud kuumaksuga (3507€+km) Framer/Webflow kodulehe valmistamise, halduse ning graafilise disaini ja ui/ux disaini tervikteenust. Saate meilt tellida piiramatult kujundustöid (nt. maandumislehed, kodulehe uuendused, reklaampostitused jne.) ja 
          no-code tarkvara arendust (Framer ja Webflow kodulehed). Enamik ülesandeid va. suuremahulised tööd valmivad tööpäevadel 48 tunni jooksul. Samal tasemel ui/ux disaineri ja veebiarendaja palkamisega võite kokku hoida üle 50% tööjõukuludest ehk säästa aastas kümmneid tuhandeid eurosid. 
        </p>
      <p>
        Soovitame oma kodulehe tegemise eelarvest rääkida ja siis saame pakkuda mida meie teeksime.</p>

        </div>
    </li>
    <li>
      <input type="checkbox" defaultChecked></input>
      <div className="faq-arrow"><Icon name="arrowleft" /></div>
      <h3>Kas te pakute ka veebilehe haldust ja hooldust?</h3>
      <div className="answer">
      <p>Jah, pakume ka kodulehe haldust!</p>
      <p>Oleme spetsialiseerunud kodulehe erilahenduste tegemisele ja kõige enam meeldib meile lahendada uusi ülesandeid siis ei jäta me teid ka peale kodulehe valmimist hätta. 
        Aitame teid nii kodulehekülje testimise, analüütika kui sisu uuendusega. Vajadusel teeme koostööd nt. SEO spetsialistide, fotograafide ja sisuloojatega.  
        </p>
      <p>
        Frameri ja Webflow no-code tarkvara põhjale ehitatud veebilehtede suur pluss on see, et nad ei vaja eraldi kodulehe tarkvara uuendamist. Wordpressiga tehtud kodulehti soovitame uuendada 1-2 korda aastas. 
         </p>

        </div>
    </li>
    <li>
      <input type="checkbox" defaultChecked></input>
      <div className="faq-arrow"><Icon name="arrowleft" /></div>
      <h3>Kuidas suurendada müüki läbi kodulehe?</h3>
      <div className="answer">
      <p>Veenduge, et teie kodulehekülg avaneb kiiresti, on kaasaegne ja usaldusväärse disainiga ning selge sisuga.</p>
      <p>Kasutage sobivaid märksõnu ja SEO-strateegiaid, et teie koduleht oleks otsingumootorites leitav. Tehke ostuprotsess võimalikult lihtsaks ja selgeks, vähendades takistusi kodulehelt ostude sooritamisel. Pakkuge kiiret ja professionaalset klienditeenindust, et vastata küsimustele ja aidata võimalike ostude tegemisel.</p>

      <p>Müügi suurendamine nõuab pidevat kodulehe testimist, jälgimist ja kohandamist vastavalt tulemustele. Olge avatud veebidisaineri uutele ideedele ja jälgige oma kodulehe analüütikat, et mõista, mis toimib kõige paremini teie sihtgrupi jaoks.</p>
      </div>
    </li>

    <li>
      <input type="checkbox" defaultChecked></input>
      <div className="faq-arrow"><Icon name="arrowleft" /></div>
    
      <h3>Kas saame ligipääsu kodulehe failidele?</h3>
      <div className="answer">
      <p>Me ei hoia kodulehe koodi lukus või ei piira teie ligipääsu. Meie ärimudel ei põhine kodulehe tellijate enda juures kinnihoidmisel.</p>
      <p>Kodulehed kujundame enamasti Figmas. Eraldi kodulehe disainifaile teile üle ei anta juhul kui tellite meilt ka kodulehe arenduse. Kui teil on oma meeskond või partner kes tegeleb veebiarendusega siis jagame kõiki vajalikke kodulehe disaini tööfaile.</p>

      <p>Kui koduleht on tehtud Frameri või Webflow tarkvara peale on teil täielik ligipääs.</p>

      <p>Wordpressi või muu ilma no-code tarkvarata arendatud kodulehekülje koodiga on keerulisem. Teil on alati ligipääs enda serverile. Aga arvestama peab sellega, et serveris olev kodulehe kood võib olla varasemalt kompileeritud ja algne veebilehe arendus on meie versioonihalduses.
      </p>

      <p>Nii kodulehe Figma disainifailide kui versioonihalduses asuva koodi jagamisel peab arvestama, et lisakasutajate lisamine on tasuline teenus ja platvormi kulud jäävad teie kanda.</p>
      </div>
    </li>

    <li>
      <input type="checkbox" defaultChecked></input>
      <div className="faq-arrow"><Icon name="arrowleft" /></div>
      <h3>Kuidas teha ise koduleht?</h3>
      <div className="answer">
      <p>Koduleht on teie ettevõtte üks kõige olulisemaid reklaamikanaleid. Jäta see töö neile veebispetsialistidele ja teenid oma investeeringu kindlasti tagasi.</p>
      <p>Kui ikka soovid ise kodulehte teha siis võiksid alustuseks lugeda läbi meie kodulehe teemalised artiklid.</p>
      <p>Isetegija võiks veebilehe valmistamise puhul vaadata Webflow või Frameri poole ja lugeda nende õpetusi ning vaadata Youtube videosid.</p>
      </div>
    </li>

    <li>
      <input type="checkbox" defaultChecked></input>
      <div className="faq-arrow"><Icon name="arrowleft" /></div>

      <h3>Kui kaua võtab kodulehe tegemine aega?</h3>
      <div className="answer">
      <p>Kodulehe tegemise aeg sõltub keerukusest, kuid keskmiselt võtab veebilehe projekt aega 4 kuni 8 nädala vahel.</p>
      <p>Palju sõltub ka sellest kui kiiresti olete te ise valmis kodulehe kujundusele tagasisidet andma ja milliseid materjale tuleb veebilehe sisu jaoks luua.</p>
      <p>Kui teil on kiiresti vaja kodulehte või kodulehe uuendust siis võtke kohe ühendust ja me leiame lahenduse. Põhjalikud teadmised kodulehtede tegemisest ja väikeagentuuri paindlikkus võimaldab meil luua lahendusi mida mujalt on raske saada.</p>
      </div>
    </li>

    <li>
      <input type="checkbox" defaultChecked></input>
      <div className="faq-arrow"><Icon name="arrowleft" /></div>

      <h3>Kuidas tagate, et tehtud koduleht vastab minu brändi isikupärale?</h3>
      <div className="answer">
      <p>Meie UI/UX disainer konsulteerib teiega, et mõista teie brändi ja visuaalset identiteeti ning integreerib need loodavasse kodulehe kujundusse.
Give veebidisainides võib leida ühtseid joonid. Lihtsad puhtad vormid ja intuitiivne kodulehtede kasutusmugavus. Samas kui pöörate tähelepanu näete, et tegelikult omame kogemust väga erinevat tüüpi ettevõtete kodulehtede kujundamisega ja leiame sobiva kujunduse igale veebilehe tüübile.
</p>
      <p>Kui teil on olemas firmastiil ja brändiraamat töötame selle põhjal. Kui brändiraamatut veel ei ole siis võibolla oleks mõistlik alustada selle kujundamisest. Lähemalt saate lugeda <ListTLink
              to="/teenused/logo-kujundamine/"
              exit={{
                length: 1,
                zIndex: 2, // exit default is 0
                trigger: ({ exit, e, node }) => animateObjects(exit, node),
              }}
              entry={{
                delay: 0.5,
                length: 0.6,
                trigger: ({ entry, node }) => newContent(node),
              }}
            >
             firmastiili ja logo kujundamise teenuse lehelt
            </ListTLink>.</p>
      </div>
    </li>

    <li>
      <input type="checkbox" defaultChecked></input>
      <div className="faq-arrow"><Icon name="arrowleft" /></div>

      <h3>Veebilehe tegemine, kust alustada?</h3>
      <div className="answer">
      <p>Kõigepealt mõtle miks soovid kodulehte teha. Kellele on koduleht mõeldud ja mis eesmärgiga?</p>
      <p>Kui tahad kodulehte teha endale ja isiklikuks kasutamiseks siis arvatavasti leiad Googeldades ja Youtubest õpetusi. Kui aga teed veebilehte oma ärile soovitan kasutada ekspertide abi.</p>
      <p>Kirjuta meile või otsi Googlest digiagentuure. Kindlasti küsi, kas pakutakse templiitide peale kodulehe valmislahendusi või erilahendusi. Valmislahenduse tegemisega saad ise ka nädalalõpuga hakkama.</p>
      <p>Selleks, et veebilehe tegemise projekt kulgeks kiiremini võid juba enne vaadata millised kodulehed sulle meeldivad. Korralik veebiagentuur kindlasti jagab omaltpoolt soovitusi aga võimalusi on palju ja alustuseks on hea teada klientide vajadusi.</p>
      </div>
    </li>

    <li>
      <input type="checkbox" defaultChecked></input>
      <div className="faq-arrow"><Icon name="arrowleft" /></div>

      <h3>Miks te minu kodulehe tegemise päringule ei ole vastanud?</h3>
      <div className="answer">
      <p>Me vastame igale kodulehe tegemise päringule. Enamasti vähemalt 24 tunni jooksul kui mitte kiiremini. Vaata spämmi kausta ja kui sealt ka meie vastust ei leia siis ole hea ning saada lihtsalt uus kodulehekülje tegemise päring. Või helista.</p>
      <p>Kui saatsid meile miski "külma" müügipakkumise siis vabandust aga jätame endale vabaduse mitte vastata</p>
     
      </div>
    </li>
  </ul>
</div>

    </div>

      </section>
      </GiveContentTrans>

      <section className="description-section inner-grid">
        <div className="content-col page-copy">
          <GiveContentTrans language="et">
          <h2>Vajad uut kodulehte või olemasoleva uuendamist?</h2>

          <p>
    Kui su koduleht ei too tulemusi või on ajale jalgu jäänud, on aeg midagi ette võtta.  
    Loome uue, kasutajasõbraliku ja kaasaegse veebilehe või analüüsime olemasolevat UI/UX lahendust, et tuvastada kitsaskohad ja parandada kasutajakogemust.  
</p>
          </GiveContentTrans>
          <GiveContentTrans language="en">
            <h2>How can we help you? </h2>
            <p>
              If your company's website needs an update or if you don't have a
              website yet, we are here. For existing websites, we offer
              UI/UX review to identify current pain points.
            </p>
          </GiveContentTrans>
        </div>
      </section>
    </main>

    </Layout>
); }

export default AboutPage;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
